import React from 'react';
import { MainLayout } from '../../components/Layout';
import TitleSection from '../../components/Partners/TitleSection';
import PartnersList from '../../components/Partners/PartnersList';
import ReferencesSection from '../../components/Partners/ReferencesSection';
import iconMairie17 from '../../assets/img/references/logo_mairie17.png';
//import iconPichetImmobilier from "../../assets/img/references/pichet-immobilier.png"
import icon1001ViesHabitat from '../../assets/img/references/1001-vies-habitat.png';

export default () => {
  const referenceList = [
    {
      icon: iconMairie17,
      content:
        'Alacaza accompagne le développement social des territoires sur lesquels leur solution se déploie. En effet, l’outil permet aux habitants, d’un même immeuble et d’un même quartier, de fluidifier et d’enrichir leurs relations grâce à l’intégration des commerçants et des associations dans une application unifiée.',
    },
    // {
    //   "icon": iconPichetImmobilier,
    //   "content": ""
    // },
    {
      icon: icon1001ViesHabitat,
      content: '',
    },
  ];

  return (
    <MainLayout
      siteTitle={`Professionnels - Découvrez nos offres`}
      siteDescription={`Découvrez nos offres destinées aux professionnels de l'immobilier et aux acteurs locaux`}
    >
      <TitleSection />
      <PartnersList />
      <ReferencesSection referenceList={referenceList} />
    </MainLayout>
  );
};
