import React from 'react';
import { Link } from 'gatsby';

const TitleSection = () => {
  return (
    <section className="container mb-10 section-partners-title">
      <div className="row">
        <div className="col-12">
          <h1 className="roboto mt-10 mt-6-md font-size-xxl font-size-xl-md text-align-center">
            Découvrez nos offres
          </h1>

          <div className="font-size-xl font-size-lg-md text-align-center pr-6 pl-6">
            Vous êtes gestionnaire, institutionnel, prestataire ou commerçant ?
            <br />
            Nous avons des applicatifs métiers spécifiques pour chacun d'entre
            vous !
          </div>
        </div>

        <div className="col-12 pt-4 text-align-center">
          <Link
            to="/contact/"
            state={{ contactType: 'partner', contactSubType: null }}
            className="btn"
          >
            Nous contacter
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TitleSection;
